:root {
  --selected: #4caf50;
  --assigned: #42a5f5;
  --inUse: #ed6c02;
  --focusOutlineColor: rgb(37, 142, 197);
  --error: #d32f2f;
  --active: #ffd700;
  --inactive: #808080;
  --drawer-in-use: #d2b48c;
  --maintenance-required: #ff6347;
  --cleaning-required: #ff1493;
  --maintenance-required-in-use: #ffa500;
  --cleaning-in-progress: #ff69b4;
  --maintenance-in-progress: #ff4500;
}
.error {
  color: var(--error);
}
.hidden {
  display: none !important;
}
.visible {
  display: block !important;
}

.data-grid-custom-asc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f062';
  font-weight: 900;
}
.data-grid-custom-desc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f063';
  font-weight: 900;
}
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable
  .MuiDataGrid-iconButtonContainer {
  display: none;
}
.dropzone {
  text-align: center;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.locker-list .MuiDataGrid-row:hover:not([class*='row-disabled']),
.user-list .MuiDataGrid-row:hover:not([class*='row-disabled']),
.customer-list .MuiDataGrid-row:hover:not([class*='row-disabled']),
.cluster-list .MuiDataGrid-row:hover:not([class*='row-disabled']) {
  cursor: pointer;
}
.selectBoxErrorField {
  width: 292px !important;
  margin-right: 14px !important;
  margin-left: 14px !important;
}
.color-legend {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(224, 224, 224);
}
.locker-deleted,
.client-inactive,
.user-deleted {
  background-color: rgba(23, 23, 23, 0.1);
}
.locker-incomplete {
  background-color: rgba(237, 108, 2, 0.1);
}
.locker-complete,
.client-active,
.user-active {
  background-color: white;
}
.labelInput-wrapper {
  align-items: flex-start;
}
.labelInput-wrapper .MuiInputLabel-root {
  overflow: visible !important;
  white-space: break-spaces !important;
}
.selected div[class^='lockers_drawer']:not([class*='control-drawer']),
div[class*='lockers_drawer'].selected:not([class^='control-drawer']) {
  background-color: var(--selected);
}
.pointer-cursor {
  cursor: pointer;
}
.selected div[class^='lockers_drawer-position'],
.assigned div[class^='lockers_drawer-position'] {
  color: white;
}
.assignable-drawer-structure div[class*='lockers_medium'],
.assignable-drawer-structure div[class*='lockers_large'] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.assigned-drawer {
  background-color: var(--assigned);
}
.drawer-selected {
  background-color: var(--selected);
}
.unassigned-drawer {
  background-color: white;
}
.active-drawer {
  background-color: var(--active);
}
.inactive-drawer {
  background-color: var(--inactive);
}
.in-use-drawer {
  background-color: var(--drawer-in-use);
}
.drawer-requires-maintenance {
  background-color: var(--maintenance-required);
}
.drawer-requires-cleaning {
  background-color: var(--cleaning-required);
}
.drawer-requires-maintenance-in-use {
  background-color: var(--maintenance-required-in-use);
}
.drawer-cleaning-in-progress {
  background-color: var(--cleaning-in-progress);
}
.drawer-maintenance-in-progress {
  background-color: var(--maintenance-in-progress);
}
div[class*='lockers_drawer'].assigned {
  background-color: var(--assigned);
}
.customer-lockers-asignement,
.customer-drawers-asignement {
  position: relative;
}

.customer-lockers-asignement .MuiDataGrid-selectedRowCount,
.customer-drawers-asignement .MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.customer-lockers-asignement .MuiDataGrid-footerContainer,
.customer-drawers-asignement .MuiDataGrid-footerContainer {
  justify-content: flex-end;
}
.selectedRowCount-custom {
  position: absolute;
  bottom: 15px;
  margin: 0 16px;
  font-size: 14px;
  visibility: hidden;
}
.MuiTablePagination-selectLabel {
  display: none;
}

.row-disabled {
  position: relative;
  color: rgb(30, 30, 30, 0.7);
}
.row-disabled:hover {
  background-color: rgb(23, 23, 23, 0.1);
  color: rgb(23, 23, 23, 0.9);
}
.row-disabled::before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgb(23, 23, 23, 0.1);
  color: rgb(23, 23, 23, 0.9);
  z-index: 1;
}

.setup-incomplete {
  position: relative;
  color: rgb(30, 30, 30, 0.7);
  cursor: default !important;
}
.setup-incomplete:hover {
  background-color: rgba(237, 108, 2, 0.1);
  color: rgb(23, 23, 23, 0.9);
}
.setup-incomplete::before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgba(237, 108, 2, 0.1);
  color: rgb(23, 23, 23, 0.9);
  z-index: 1;
}
.locker-in-cluster {
  background-color: rgba(255, 171, 145, 0.8);
}
.locker-in-cluster:hover {
  background-color: rgba(255, 171, 145, 1) !important;
}
div[class*='setup-incomplete'] div[data-field='actions'],
div[class*='row-disabled'] div[class*='action-buttons'] {
  z-index: 1;
}
.connected-locker {
  border-left: 10px solid var(--assigned);
}
.autonomous-locker {
  border-left: 10px solid var(--selected);
}
.locker-type-connected {
  background-color: var(--assigned);
}
.locker-type-autonomous {
  background-color: var(--selected);
}
.cluster-locker {
  background-color: rgba(255, 171, 145, 0.8);
}

[data-rbd-droppable-id='cluster-container'] {
  display: inline-flex;
  border: 2px solid black;
  padding: 10px;
  column-gap: 10px;
}
.draggable-locker {
  border: 2px solid black;
  width: 150px;
  height: 564px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}
div[class*='drawer-in-use'] {
  position: relative;
}
div[class*='drawer-in-use']::after {
  width: 10px;
  height: 10px;
  bottom: 2px;
  left: 2px;
  content: '';
  position: absolute;
  background-color: var(--inUse);
  z-index: 1;
  border-radius: 50%;
}

.customDialog .MuiDialog-container.MuiDialog-scrollPaper {
  width: 100%;
}
.textarea-with-copy {
  border-color: rgba(0, 0, 0, 0.12);
}
.textarea-with-copy:focus {
  outline: 2px solid var(--focusOutlineColor);
}
.textarea-no-resize {
  resize: none;
}

.uptime-level.average {
  background-color: var(--inUse);
  color: #fff;
}
.uptime-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.uptime-level.bellow-average {
  background-color: var(--error);
  color: #fff;
}

.load-level.average {
  background-color: var(--inUse);
  color: #fff;
}
.load-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.load-level.bellow-average {
  background-color: var(--error);
  color: #fff;
}

.month-picker
  div[role='presentation']
  .PrivatePickersFadeTransitionGroup-root:nth-of-type(2) {
  display: none !important;
}

.full-screen-dialog .MuiPaper-root {
  background-color: transparent;
}
.lockers-map-container {
  background-color: white;
  margin: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(241, 128, 23, 0.6);
  text-align: center;
  border-radius: 15px;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

.account-link {
  display: flex;
  text-decoration: none;
  color: white;
}

.unauthenticated-header .language-selector {
  margin-right: 16px;
  margin-left: 0;
  margin-bottom: 0;
}
.unauthenticated-header
  .language-selector
  .Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #0000003b;
}

.unauthenticated-header
  .language-selector
  .MuiOutlinedInput-root.MuiInputBase-root {
  color: white;
}

div[class*='lockers_drawer'] .drawer-state-container {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
div[class*='lockers_drawer'] .drawer-state-container.active {
  background-color: var(--active);
}
div[class*='lockers_drawer'] .drawer-state-container.inactive {
  background-color: var(--inactive);
}
div[class*='lockers_drawer'] .drawer-state-container.in-use {
  background-color: var(--drawer-in-use);
}
div[class*='lockers_drawer'] .drawer-state-container.maintenance-required {
  background-color: var(--maintenance-required);
}
div[class*='lockers_drawer'] .drawer-state-container.cleaning-required {
  background-color: var(--cleaning-required);
}
div[class*='lockers_drawer']
  .drawer-state-container.maintenance-required-in-use {
  background-color: var(--maintenance-required-in-use);
}
div[class*='lockers_drawer'] .drawer-state-container.cleaning-in-progress {
  background-color: var(--cleaning-in-progress);
}
div[class*='lockers_drawer'] .drawer-state-container.maintenance-in-progress {
  background-color: var(--maintenance-in-progress);
}

.locker-structure-container {
  min-height: 600px;
}

.locker-structure-container .drawer-container {
  position: relative;
  cursor: pointer;
}

.locker-structure-container .drawer-container .open-drawer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row-cell-subtext {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locker-capabilities .hardaware-capabilities {
  margin-left: 0;
}

.locality-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 190px;
}

.locker-central-unit-type div[class*='lockers_column-head'] {
  display: none;
}

.locker-central-unit-type div[class*='lockers_locker-container'] {
  flex: initial;
}

.striped-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.striped-table tbody tr:last-child td {
  border: 0;
}

.events-count {
  font-size: 40px;
  font-weight: bold;
}

.events-count.no-event {
  color: var(--selected);
}
.events-count.has-events {
  color: var(--error);
}

.dashboard-counter-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  font-size: 40px;
  min-width: 130px;
}
.dashboard-activities-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  font-size: 20px;
}
.total-count-container {
  font-size: 40px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.category-count-container {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

#apexchartsbrush-bottom-chart .apexcharts-yaxis {
  display: none;
}

#apexchartspackage-time-in-locker-chart .apexcharts-legend {
  flex-direction: column;
  gap: 8px;
}

#apexchartspackage-time-in-locker-chart .apexcharts-legend-series {
  display: flex;
  align-items: center;
}

.dashboard-container {
  display: grid;
  gap: 16px;
}

.events-container {
  display: grid;
  gap: 16px;
}

.battery-container {
  display: grid;
  gap: 16px;
}

.three-days-chart-container,
.seven-days-chart-container {
  display: grid;
  gap: 16px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: 16px;
}

.cards-container > * {
  grid-column: col-start / span 12;
}

.connected-locker-structure div[class*='column-body'] {
  height: 634px;
}

.autonomous-locker-structure div[class*='column-head'] {
  display: none;
}

.autonomous-locker-structure.AUT1 div[class*='column-head'] {
  display: block;
}

.add-identifier-container div[class*='MuiStack-root'] {
  margin-bottom: 0px;
}

.assign-drawers-container {
  max-width: 900px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow-y: auto;
  height: 300px;
  position: absolute;
  z-index: 100;
  background-color: white;
  margin-top: 5px;
}

.drawer-types-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
}

.awb-ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.awb-cell {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.low-battery-badge {
  background-color: #d32f2f;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
}

@media (min-width: 600px) {
  .MuiTablePagination-selectLabel {
    display: none !important;
  }
  .MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
  }
  .appVersionDataGridContainer .MuiDataGrid-virtualScroller {
    overflow-x: hidden !important;
  }
  .activity-smart-key-container {
    margin-left: -3px !important;
  }
}

@media (min-width: 900px) {
  .labelInput-wrapper {
    align-items: center;
  }
  .MuiTablePagination-selectLabel,
  .MuiDataGrid-selectedRowCount {
    display: block !important;
  }
  .MuiDataGrid-selectedRowCount {
    visibility: visible !important;
  }
  .selectedRowCount-custom {
    visibility: visible;
    width: auto;
    height: auto;
  }
  .events-container {
    grid-template-columns: 1fr 1fr;
  }

  .events-container > div {
    flex: 50%;
  }

  .seven-days-chart-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .lockers-card {
    grid-column: col-start / span 6;
    grid-row: 1;
  }
  .drawers-card {
    grid-column: col-start 7 / span 6;
    grid-row: 1;
  }
  .packages-card {
    grid-column: col-start / span 6;
    grid-row: 2;
  }
  .customers-card {
    grid-column: col-start 7 / span 6;
    grid-row: 2;
  }
  .activities-card {
    grid-column: col-start / span 8;
  }
  .users-card {
    grid-column: col-start 9 / span 4;
  }
}

@media (min-width: 1200px) {
  .lockers-card {
    grid-column: col-start / span 4;
    grid-row: 1;
  }
  .drawers-card {
    grid-column: col-start 5 / span 4;
    grid-row: 1;
  }
  .packages-card {
    grid-column: col-start 9 / span 4;
    grid-row: 1;
  }
  .customers-card {
    grid-column: col-start / span 3;
    grid-row: 2;
  }
  .activities-card {
    grid-column: col-start 4 / span 6;
  }
  .users-card {
    grid-column: col-start 10 / span 3;
  }
  .battery-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1536px) {
  .events-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .three-days-chart-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1920px) {
  #root {
    max-width: 1920px;
    position: relative;
  }
  .main-header {
    max-width: 1740px;
    right: auto !important;
  }
  .spinner-container {
    position: absolute !important;
    min-height: 100vh;
  }
}
